import clsx from 'clsx';
import Image from 'next/image';

export function Logomark(props) {
  return (
    <Image
      width={32}
      height={32}
      src="/img/upgrow-logo-icon.svg"
      loading="eager"
      alt="Keesy logo icon"
      title='Keesy logo icon'
      priority={true}
      {...props}
    />
    // <svg viewBox="0 0 40 40" aria-hidden="true" {...props}>
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20ZM4 20c0 7.264 5.163 13.321 12.02 14.704C17.642 35.03 19 33.657 19 32V8c0-1.657-1.357-3.031-2.98-2.704C9.162 6.68 4 12.736 4 20Z"
    //   />
    // </svg>
  );
}

export function Logo(props) {
  return (
    <div className={clsx('group flex items-center gap-2', props.className, props.center ? '!justify-center' : '')}>

      {/* {!props.hideIcon && (
        <Logomark
          width="32"
          height="32"
          className="inline-block h-8 w-auto transform transform-gpu transition-all duration-500 ease-in-out group-hover:translate-x-1 group-hover:rotate-[360deg] group-hover:scale-[87%]"
        />
      )} */}

      {!props.hideText && (
        <Image
          width={135}
          height={20}
          src="/img/keesy-logo.svg"
          loading="eager"
          alt="Keesy logo"
          title='Keesy logo'
          className="inline-block w-auto mt-2 h-7 saturate-[1.15] hover:scale-95 transition-transform duration-300 ease-in-out cursor-pointer"
          priority={true}
        />
      )}
    </div>
  );
}
