'use client';
import '../../output.css';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';
import Image from 'next/image';
import { Logo } from '@/components/landing/navigation/Logo';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { classNames } from '@/lib/utils';
import Anchor from 'shared-ui/components/Anchor';


export default function ClientLayout({ children }: { children: ReactNode }) {

  const queryClient = new QueryClient();
  const pathname = usePathname();
  const isLoggedInSetup = pathname.startsWith('/app/start');
  const isLogin = pathname.startsWith('/login');

  return (
    <QueryClientProvider client={queryClient}>
      <style jsx global>{`
      .hero-background-wrap {
        width: 100%;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        position: absolute;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
    }
    .hero-background-gradient {
      width: 100%;
      height: 100%;
      max-height: 100%;
      background-image: url(https://assets-global.website-files.com/65e8cb7da76e6790c4e6a110/65e8cb7da76e6790c4e6a11e_Hero%20BG.webp);
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 16px;
      position: relative;
  }
  .bg-lights {
    width: 100%;
    max-width: 1188px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 41px;
    padding-right: 40px;
    display: flex;
    position: relative;
}

.floating-card.followers {
  animation-delay: -1.2s;
  animation-duration: 5s;
}

.floating-card.followers {
  animation-delay: -.4s;
  animation-duration: 5.2s;
}
.floating-card.followers {
  top: 12%;
  left: 8%;
}
.floating-card.story-views {
  animation-delay: -.1s;
  animation-duration: 4.8s;
}
.floating-card.story-views {
  top: 13%;
  right: 7%;
}
.floating-card.likes {
  top: 40%;
  right: 13.1%;
}
.floating-card.comments {
  animation-delay: -.7s;
  animation-duration: 5.6s;
}
.floating-card.comments {
  top: 39%;
  left: 11.5%;
}
.floating-card {
  pointer-events: none;
  visibility: visible !important;
  animation-name: floatingCard;
  animation-timing-function: ease;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.floating-card {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  color: var(--main);
  background-color: rgba(255, 255, 255, .4);
  border: 1px solid rgba(255, 255, 255, .24);
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 12px 6px 6px;
  display: flex;
  position: absolute;
}
.floating-card-content {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}
.floating-card-icon {
  width: 16px;
  height: 16px;
}
.floating-card-number {
  font-weight: 500;
}

      @keyframes waterfall {
        0% {transform: translateY(-120px); opacity: 0}
        50% {transform: translateY(600px); opacity: 1}
        100% {transform: translateY(1200px); opacity: 0}
        }
        
        @keyframes floatingCard {
          0% {
            transform: translateY(8px);
          }
          50% {
              transform: translateY(0);
          }
          100% {
              transform: translateY(8px);
          }
        }

        .lines-block {
        
          animation-name: waterfall;
          animation-duration: 6s;
          animation-timing-function: ease;
          animation-iteration-count: infinite;
          animation-fill-mode: both;
          
        }
        
        .lines-block:nth-child(2) {
          animation-delay: 0.2s
        }
        `}</style>

      <div className="grid h-screen overflow-hidden" style={{ gridTemplateRows: 'auto 1fr' }}>
        <div className="h-[100vh] min-h-full lg:flex lg:justify-between">
          <div className={classNames("relative h-full flex flex-col justify-start lg:justify-center flex-1 px-4 py-12 sm:px-6 lg:px-20 xl:px-24", !isLoggedInSetup && 'self-center')}>

            {/* Background */}
            <div className="hero-background-wrap">
              <div className="hero-background-gradient">
                <div className="bg-lights">
                  <div className="lines-block">
                    <div className="hero-light-line"></div>
                    <div className="hero-light-line"></div>
                    <div className="hero-light-line"></div>
                  </div>
                  <div className="lines-block">
                    <div className="hero-light-line"></div>
                    <div className="hero-light-line"></div>
                    <div className="hero-light-line"></div>
                  </div>
                  <div className="embed w-embed"></div>
                </div>
                <div className="absolute !hidden lg:!inline-flex floating-card followers" style={{ visibility: 'hidden' }}>
                  <div className="floating-card-image">
                    <img
                      src="https://assets-global.website-files.com/65e8cb7da76e6790c4e6a110/65e8cb7da76e6790c4e6a12d_hero-avatar-2.webp"
                      loading="lazy"
                      width="32"
                      alt="User avatar"
                    />
                  </div>
                  <div className="floating-card-content">
                    <div className="floating-card-icon">
                      <div className="svg w-embed">
                        <svg fill="none" viewBox="0 0 16 16">
                          <path
                            fill="url(#paint0_linear_908_68)"
                            d="M6.66683 8c1.65685 0 3-1.34315 3-3s-1.34315-3-3-3-3 1.34315-3 3 1.34315 3 3 3Z"
                          ></path>
                          <path
                            fill="url(#paint1_linear_908_68)"
                            fillOpacity=".16"
                            d="M6.66683 8c1.65685 0 3-1.34315 3-3s-1.34315-3-3-3-3 1.34315-3 3 1.34315 3 3 3Z"
                          ></path>
                          <path
                            stroke="url(#paint2_linear_908_68)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M8.00016 10.3333H5.00017c-.93038 0-1.39557 0-1.7741.1149-.85227.2585-1.51921.9254-1.77775 1.7777-.11482.3785-.11482.8437-.11482 1.7741m11.3333 0v-4m-2 2h4M9.66683 5c0 1.65685-1.34315 3-3 3s-3-1.34315-3-3 1.34315-3 3-3 3 1.34315 3 3Z"
                          ></path>
                          <path
                            stroke="url(#paint3_linear_908_68)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeOpacity=".16"
                            strokeWidth="1.5"
                            d="M8.00016 10.3333H5.00017c-.93038 0-1.39557 0-1.7741.1149-.85227.2585-1.51921.9254-1.77775 1.7777-.11482.3785-.11482.8437-.11482 1.7741m11.3333 0v-4m-2 2h4M9.66683 5c0 1.65685-1.34315 3-3 3s-3-1.34315-3-3 1.34315-3 3-3 3 1.34315 3 3Z"
                          ></path>
                          <defs>
                            <linearGradient
                              id="paint0_linear_908_68"
                              x1="-4.75694"
                              x2="6.83294"
                              y1="8.17287"
                              y2="21.0505"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#7549F4"></stop>
                              <stop offset="1" stopColor="#FCA6E9"></stop>
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_908_68"
                              x1="8.00016"
                              x2="8.00016"
                              y1="2"
                              y2="14"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#fff"></stop>
                              <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
                            </linearGradient>
                            <linearGradient
                              id="paint2_linear_908_68"
                              x1="-4.75694"
                              x2="6.83294"
                              y1="8.17287"
                              y2="21.0505"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#7549F4"></stop>
                              <stop offset="1" stopColor="#FCA6E9"></stop>
                            </linearGradient>
                            <linearGradient
                              id="paint3_linear_908_68"
                              x1="8.00016"
                              x2="8.00016"
                              y1="2"
                              y2="14"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#fff"></stop>
                              <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <div className="floating-card-number">1320</div>
                    <div className="floating-card-text">Followers</div>
                  </div>
                </div>
                <div className="absolute !hidden lg:!inline-flex floating-card comments" style={{ visibility: 'hidden' }}>
                  <div className="floating-card-image">
                    <img
                      src="https://assets-global.website-files.com/65e8cb7da76e6790c4e6a110/65e8cb7da76e6790c4e6a134_hero-avatar-4.webp"
                      loading="lazy"
                      width="32"
                      alt="User avatar"
                    />
                  </div>
                  <div className="floating-card-content">
                    <div className="floating-card-icon">
                      <div className="svg w-embed">
                        <svg fill="none" viewBox="0 0 16 16">
                          <path
                            fill="url(#paint0_linear_908_121)"
                            d="M14 8c0 3.3137-2.6863 6-6 6-.7981 0-1.5598-.1558-2.25634-.4387-.13331-.0541-.19997-.0812-.25385-.0933-.0527-.0118-.09171-.0161-.14572-.0161-.05522 0-.11537.01-.23566.03l-2.37192.3953c-.24839.0414-.37258.0621-.46239.0236a.33323.33323 0 0 1-.17495-.1749c-.03852-.0898-.01782-.214.02358-.4624l.39532-2.3719c.02005-.1203.03007-.1805.03007-.2357-.00001-.054-.00433-.093-.01614-.1457-.01208-.0539-.03915-.1205-.09329-.2539C2.15582 9.5598 2 8.7981 2 8c0-3.31371 2.68629-6 6-6 3.3137 0 6 2.68629 6 6Z"
                          ></path>
                          <path
                            fill="url(#paint1_linear_908_121)"
                            fillOpacity=".16"
                            d="M14 8c0 3.3137-2.6863 6-6 6-.7981 0-1.5598-.1558-2.25634-.4387-.13331-.0541-.19997-.0812-.25385-.0933-.0527-.0118-.09171-.0161-.14572-.0161-.05522 0-.11537.01-.23566.03l-2.37192.3953c-.24839.0414-.37258.0621-.46239.0236a.33323.33323 0 0 1-.17495-.1749c-.03852-.0898-.01782-.214.02358-.4624l.39532-2.3719c.02005-.1203.03007-.1805.03007-.2357-.00001-.054-.00433-.093-.01614-.1457-.01208-.0539-.03915-.1205-.09329-.2539C2.15582 9.5598 2 8.7981 2 8c0-3.31371 2.68629-6 6-6 3.3137 0 6 2.68629 6 6Z"
                          ></path>
                          <path
                            stroke="url(#paint2_linear_908_121)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M14 8c0 3.3137-2.6863 6-6 6-.7981 0-1.5598-.1558-2.25634-.4387-.13331-.0541-.19997-.0812-.25385-.0933-.0527-.0118-.09171-.0161-.14572-.0161-.05522 0-.11537.01-.23566.03l-2.37192.3953c-.24839.0414-.37258.0621-.46239.0236a.33323.33323 0 0 1-.17495-.1749c-.03852-.0898-.01782-.214.02358-.4624l.39532-2.3719c.02005-.1203.03007-.1805.03007-.2357-.00001-.054-.00433-.093-.01614-.1457-.01208-.0539-.03915-.1205-.09329-.2539C2.15582 9.5598 2 8.7981 2 8c0-3.31371 2.68629-6 6-6 3.3137 0 6 2.68629 6 6Z"
                          ></path>
                          <path
                            stroke="url(#paint3_linear_908_121)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeOpacity=".16"
                            strokeWidth="1.5"
                            d="M14 8c0 3.3137-2.6863 6-6 6-.7981 0-1.5598-.1558-2.25634-.4387-.13331-.0541-.19997-.0812-.25385-.0933-.0527-.0118-.09171-.0161-.14572-.0161-.05522 0-.11537.01-.23566.03l-2.37192.3953c-.24839.0414-.37258.0621-.46239.0236a.33323.33323 0 0 1-.17495-.1749c-.03852-.0898-.01782-.214.02358-.4624l.39532-2.3719c.02005-.1203.03007-.1805.03007-.2357-.00001-.054-.00433-.093-.01614-.1457-.01208-.0539-.03915-.1205-.09329-.2539C2.15582 9.5598 2 8.7981 2 8c0-3.31371 2.68629-6 6-6 3.3137 0 6 2.68629 6 6Z"
                          ></path>
                          <defs>
                            <linearGradient
                              id="paint0_linear_908_121"
                              x1="-3.48139"
                              x2="8.17287"
                              y1="8.17287"
                              y2="19.8271"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#7549F4"></stop>
                              <stop offset="1" stopColor="#FCA6E9"></stop>
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_908_121"
                              x1="8"
                              x2="8"
                              y1="2"
                              y2="14"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#fff"></stop>
                              <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
                            </linearGradient>
                            <linearGradient
                              id="paint2_linear_908_121"
                              x1="-3.48139"
                              x2="8.17287"
                              y1="8.17287"
                              y2="19.8271"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#7549F4"></stop>
                              <stop offset="1" stopColor="#FCA6E9"></stop>
                            </linearGradient>
                            <linearGradient
                              id="paint3_linear_908_121"
                              x1="8"
                              x2="8"
                              y1="2"
                              y2="14"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#fff"></stop>
                              <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <div className="floating-card-number">2152</div>
                    <div className="floating-card-text">Comments</div>
                  </div>
                </div>
                <div className="absolute !hidden lg:!inline-flex floating-card story-views" style={{ visibility: 'hidden' }}>
                  <div className="floating-card-image">
                    <img
                      src="https://assets-global.website-files.com/65e8cb7da76e6790c4e6a110/65e8cb7da76e6790c4e6a133_hero-avatar-1.webp"
                      loading="lazy"
                      width="32"
                      alt="User avatar"
                    />
                  </div>
                  <div className="floating-card-content">
                    <div className="floating-card-icon">
                      <div className="svg w-embed">
                        <svg fill="none" viewBox="0 0 16 16">
                          <path
                            fill="url(#paint0_linear_908_4795)"
                            d="M9.16239 1.3953c.02753-.22025-.09344-.43229-.29704-.52067-.20361-.08839-.44111-.03196-.58321.13856L2.33169 8.15373c-.10523.12624-.20495.24587-.27637.34929-.06841.09903-.17404.26831-.1773.48436a.83333.83333 0 0 0 .30947.66072c.16806.13582.36573.16303.4856.17388.12517.01134.2809.01132.44524.0113h4.31486l-.59641 4.77132c-.02753.2202.09343.4323.29704.5207.20361.0883.44111.0319.58321-.1386l5.95047-7.14057c.1052-.12621.2049-.24586.2763-.34925.0685-.09904.1741-.26831.1773-.48437a.83318.83318 0 0 0-.3094-.66072c-.1681-.13581-.3658-.16303-.4856-.17388-.1252-.01133-.2809-.01132-.4452-.0113H8.56597l.59642-4.77131Z"
                          ></path>
                          <defs>
                            <linearGradient
                              id="paint0_linear_908_4795"
                              x1="-.14931"
                              x2="17.6091"
                              y1="19.825"
                              y2="18.2597"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop offset=".0001" stopColor="#F826D3"></stop>
                              <stop offset="1" stopColor="#EFBA80"></stop>
                              <stop offset="1" stopColor="#EEB577"></stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <div className="floating-card-number">2455</div>
                    <div className="floating-card-text">Story Views</div>
                  </div>
                </div>
                <div className="absolute !hidden lg:!inline-flex floating-card likes" style={{ visibility: 'hidden' }}>
                  <div className="floating-card-image">
                    <img
                      src="https://assets-global.website-files.com/65e8cb7da76e6790c4e6a110/65e8cb7da76e6790c4e6a130_hero-avatar-3.webp"
                      loading="lazy"
                      width="32"
                      alt="User avatar"
                    />
                  </div>
                  <div className="floating-card-content">
                    <div className="floating-card-icon">
                      <div className="svg w-embed">
                        <svg fill="none" viewBox="0 0 16 16">
                          <path
                            fill="url(#paint0_linear_908_4790)"
                            d="M10.7404 2c2.3482 0 3.9259 2.235 3.9259 4.32 0 4.2225-6.54811 7.68-6.66663 7.68-.11851 0-6.66666-3.4575-6.66666-7.68 0-2.085 1.57778-4.32 3.92592-4.32 1.34815 0 2.22963.6825 2.74074 1.2825C8.51079 2.6825 9.39227 2 10.7404 2Z"
                          ></path>
                          <defs>
                            <linearGradient
                              id="paint0_linear_908_4790"
                              x1="-.87472"
                              x2="18.3621"
                              y1="17.9"
                              y2="15.6944"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop offset=".0001" stopColor="#F826D3"></stop>
                              <stop offset="1" stopColor="#EFBA80"></stop>
                              <stop offset="1" stopColor="#EEB577"></stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <div className="floating-card-number">1241</div>
                    <div className="floating-card-text">Likes</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='relative flex items-center justify-center w-full max-w-xl p-2 mx-auto bg-gradient-to-tr from-[rgba(249,77,219,0.32)] to-[rgba(243,202,158,0.32)] lg:max-w-lg rounded-[20px]'>
              <img src="/img/graphics/login-element-1.png" alt="Login element" className="absolute pointer-events-none" role='presentation'
                style={{
                  width: '200px',
                  height: '200px',
                  top: '15%',
                  left: '-110px'
                }}
                loading="lazy"
              />
              <img src="/img/graphics/login-element-2.png" alt="Login element" className="absolute pointer-events-none" role='presentation'
                style={{
                  width: '200px',
                  height: '200px',
                  bottom: '15%',
                  right: '-90px'
                }}
                loading="lazy"
              />
              <div className='absolute top-[1px] bottom-[1px] left-[1px] right-[1px] w-[calc(100%-2px)] h-[calc(100%-2px)] bg-gradient-to-r from-[#f2e6f8] to-[#f8f0f5] backdrop-blur-md rounded-[20px]' />
              <div className="relative flex-1 w-full max-w-xl px-8 mx-auto overflow-hidden bg-white rounded-[20px] py-7 lg:max-w-lg border-1 backdrop-blur-md"
                style={{
                  boxShadow: "0px 64px 132px -20px rgba(158, 152, 174, 0.08)",
                }}
              >

                <Anchor href="/">
                  <Logo className="justify-center cursor-pointer" />
                </Anchor>
                {children}
              </div>
            </div>

          </div>

        </div>
        {/* <div className="row-auto">
          <MaintenanceModeBanner
            initialIsAppInMaintenanceMode={initialIsAppInMaintenanceMode}
          />
        </div> */}
      </div>
    </QueryClientProvider>
  );
}

